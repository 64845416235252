import React, { Component } from "react";
import { withRouter } from "react-router";
import axios from "axios";
import "./Form.css";
import "./FormStyles.scss";

class Form extends Component {
  constructor() {
    super();
    this.state = {
      message: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      positions: {
        isRestaurantCrew: false,
        isManager: false,
        isDishwasher: false,
        isCook: false,
      },
      availableStartDate: "",
      daytimeAvailability: {
        daytimeMonday: false,
        daytimeTuesday: false,
        daytimeWednesday: false,
        daytimeThursday: false,
        daytimeFriday: false,
        daytimeSaturday: false,
        daytimeSunday: false,
      },
      nighttimeAvailability: {
        nighttimeMonday: false,
        nighttimeTuesday: false,
        nighttimeWednesday: false,
        nighttimeThursday: false,
        nighttimeFriday: false,
        nighttimeSaturday: false,
        nighttimeSunday: false,
      },
      typeOfEmployment: {
        fullTime: false,
        partTime: false,
        seasonal: false,
        temporary: false,
      },
      haveApplied: "",
      legallyAllowedToWorkInUS: "",
      highschoolName: "",
      highschoolLocation: "",
      collegeName: "",
      collegeLocation: "",
      highschoolStatus: "",
      collegeStatus: "",
      firstCompanyName: "",
      firstJobTitle: "",
      firstJobStartDate: "",
      firstJobEndDate: "",
      firstJobResponsibilities: "",
      firstJobReasonForLeaving: "",
      secondCompanyName: "",
      secondJobTitle: "",
      secondJobStartDate: "",
      secondJobEndDate: "",
      secondJobResponsibilities: "",
      secondJobReasonForLeaving: "",
      thirdCompanyName: "",
      thirdJobTitle: "",
      thirdJobStartDate: "",
      thirdJobEndDate: "",
      thirdJobResponsibilities: "",
      thirdJobReasonForLeaving: "",
      signature: "",
      signDate: "",
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeDaytime = this.handleChangeDaytime.bind(this);
    this.handleChangeNighttime = this.handleChangeNighttime.bind(this);
    this.handleChangeEmploymentType =
      this.handleChangeEmploymentType.bind(this);
  }

  handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    type === "checkbox"
      ? this.setState((prevState) => {
          return {
            positions: {
              ...prevState.positions,
              [name]: checked,
            },
          };
        })
      : this.setState({
          [name]: value,
        });
  };

  handleChangeDaytime = (e) => {
    const { name, type, checked } = e.target;
    if (type === "checkbox") {
      this.setState((prevState) => {
        return {
          daytimeAvailability: {
            ...prevState.daytimeAvailability,
            [name]: checked,
          },
        };
      });
    }
  };

  handleChangeNighttime = (e) => {
    const { name, type, checked } = e.target;
    if (type === "checkbox") {
      this.setState((prevState) => {
        return {
          nighttimeAvailability: {
            ...prevState.nighttimeAvailability,
            [name]: checked,
          },
        };
      });
    }
  };

  handleChangeEmploymentType = (e) => {
    const { name, type, checked } = e.target;
    if (type === "checkbox") {
      this.setState((prevState) => {
        return {
          typeOfEmployment: {
            ...prevState.typeOfEmployment,
            [name]: checked,
          },
        };
      });
    }
  };

  async handleSubmit(e) {
    e.preventDefault();

    const {
      firstName,
      lastName,
      phoneNumber,
      email,
      positions,
      availableStartDate,
      daytimeAvailability,
      nighttimeAvailability,
      typeOfEmployment,
      haveApplied,
      legallyAllowedToWorkInUS,
      highschoolName,
      highschoolLocation,
      collegeName,
      collegeLocation,
      highschoolStatus,
      collegeStatus,
      firstCompanyName,
      firstJobTitle,
      firstJobStartDate,
      firstJobEndDate,
      firstJobResponsibilities,
      firstJobReasonForLeaving,
      secondCompanyName,
      secondJobTitle,
      secondJobStartDate,
      secondJobEndDate,
      secondJobResponsibilities,
      secondJobReasonForLeaving,
      thirdCompanyName,
      thirdJobTitle,
      thirdJobStartDate,
      thirdJobEndDate,
      thirdJobResponsibilities,
      thirdJobReasonForLeaving,
      signature,
      signDate,
    } = this.state;

    const positionsIsTrue = Object.keys(positions).filter(
      (position) => positions[position] === true
    );

    const daytimeAvailabilityIsTrue = Object.keys(daytimeAvailability).filter(
      (availability) => daytimeAvailability[availability] === true
    );

    const nighttimeAvailabilityIsTrue = Object.keys(
      nighttimeAvailability
    ).filter((availability) => nighttimeAvailability[availability] === true);

    const typeOfEmploymentIsTrue = Object.keys(typeOfEmployment).filter(
      (employmentType) => typeOfEmployment[employmentType] === true
    );

    try {
      const applicationForm = await axios.post("/api/form", {
        firstName,
        lastName,
        phoneNumber,
        email,
        positionsIsTrue,
        availableStartDate,
        daytimeAvailabilityIsTrue,
        nighttimeAvailabilityIsTrue,
        typeOfEmploymentIsTrue,
        haveApplied,
        legallyAllowedToWorkInUS,
        highschoolName,
        highschoolLocation,
        collegeName,
        collegeLocation,
        highschoolStatus,
        collegeStatus,
        firstCompanyName,
        firstJobTitle,
        firstJobStartDate,
        firstJobEndDate,
        firstJobResponsibilities,
        firstJobReasonForLeaving,
        secondCompanyName,
        secondJobTitle,
        secondJobStartDate,
        secondJobEndDate,
        secondJobResponsibilities,
        secondJobReasonForLeaving,
        thirdCompanyName,
        thirdJobTitle,
        thirdJobStartDate,
        thirdJobEndDate,
        thirdJobResponsibilities,
        thirdJobReasonForLeaving,
        signature,
        signDate,
      });
    } catch (e) {
      console.log(e);
    }

    this.props.history.push("/thank-you");
  }

  render() {
    return (
      <div className="careers">
        <div id="index-banner" className="header-careers">
          <div className="header-title">
            <br />
            <h1 className="center">CAREERS</h1>
          </div>
        </div>

        <div className="container valign-wrapper next section careers__container">
          <form className="careers__form" onSubmit={this.handleSubmit}>
            <h4 className="careers__title">
              Complete Our Online Application Today!
            </h4>
            <br />
            <div className="careers__name">
              {/***********************************************************/}
              {/************************ First Name ***********************/}
              {/***********************************************************/}
              <div>
                <input
                  type="text"
                  placeholder="First Name"
                  required
                  name="firstName"
                  value={this.state.firstName}
                  onChange={this.handleChange}
                />
              </div>
              {/***********************************************************/}
              {/************************* Last Name ***********************/}
              {/***********************************************************/}
              <div className="col s12 m6">
                <input
                  type="text"
                  placeholder="Last Name"
                  required
                  name="lastName"
                  value={this.state.lastName}
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <div className="careers__persons--info">
              {/***********************************************************/}
              {/************************ Email ****************************/}
              {/***********************************************************/}
              <div>
                <input
                  type="text"
                  placeholder="Email Address"
                  required
                  name="email"
                  value={this.state.email}
                  onChange={this.handleChange}
                />
              </div>
              {/***********************************************************/}
              {/************************ Phone Number *********************/}
              {/***********************************************************/}
              <div>
                <input
                  type="text"
                  placeholder="Phone Number"
                  required
                  name="phoneNumber"
                  value={this.state.phoneNumber}
                  onChange={this.handleChange}
                />
              </div>
            </div>
            {/***********************************************************/}
            {/************************ Positions ************************/}
            {/***********************************************************/}
            <div>
              <legend>Positions interested in *</legend>
              <div className="careers__positions">
                <label>
                  <input
                    type="checkbox"
                    name="isRestaurantCrew"
                    checked={this.state.positions.isRestaurantCrew}
                    onChange={this.handleChange}
                  />
                  <span>Restaurant Crew</span>
                </label>

                <label>
                  <input
                    type="checkbox"
                    name="isManager"
                    checked={this.state.positions.isManager}
                    onChange={this.handleChange}
                  />
                  <span>Manager</span>
                </label>

                <label>
                  <input
                    type="checkbox"
                    name="isDishwasher"
                    checked={this.state.positions.isDishwasher}
                    onChange={this.handleChange}
                  />
                  <span>Dish Washer</span>
                </label>

                <label>
                  <input
                    type="checkbox"
                    name="isCook"
                    checked={this.state.positions.isCook}
                    onChange={this.handleChange}
                  />
                  <span>Cook</span>
                </label>
              </div>
            </div>
            {/***********************************************************/}
            {/*********************** Availability Date *****************/}
            {/***********************************************************/}
            <div className="careers__availability--date">
              <label for="available-date">Available Start Date:</label>
              <input
                type="date"
                size="20"
                title="available"
                name="availableStartDate"
                value={this.state.availableStartDate}
                onChange={this.handleChange}
              />
            </div>
            {/***********************************************************/}
            {/*********************** Schedule Days *********************/}
            {/***********************************************************/}
            <div className="careers__availability--days">
              <div>
                <legend>
                  Daytime Availability
                  <span className="careers__availability--times">
                    (11am-5pm)
                  </span>{" "}
                  *
                </legend>

                <div className="careers__availability--day">
                  <label>
                    <input
                      type="checkbox"
                      name="daytimeMonday"
                      checked={this.state.daytimeAvailability.daytimeMonday}
                      onChange={this.handleChangeDaytime}
                    />
                    <span>Monday</span>
                  </label>

                  <label>
                    <input
                      type="checkbox"
                      name="daytimeTuesday"
                      checked={this.state.daytimeAvailability.daytimeTuesday}
                      onChange={this.handleChangeDaytime}
                    />
                    <span>Tuesday</span>
                  </label>

                  <label>
                    <input
                      type="checkbox"
                      name="daytimeWednesday"
                      checked={this.state.daytimeAvailability.daytimeWednesday}
                      onChange={this.handleChangeDaytime}
                    />
                    <span>Wednesday</span>
                  </label>

                  <label>
                    <input
                      type="checkbox"
                      name="daytimeThursday"
                      checked={this.state.daytimeAvailability.daytimeThursday}
                      onChange={this.handleChangeDaytime}
                    />
                    <span>Thursday</span>
                  </label>

                  <label>
                    <input
                      type="checkbox"
                      name="daytimeFriday"
                      checked={this.state.daytimeAvailability.daytimeFriday}
                      onChange={this.handleChangeDaytime}
                    />
                    <span>Friday</span>
                  </label>

                  <label>
                    <input
                      type="checkbox"
                      name="daytimeSaturday"
                      checked={this.state.daytimeAvailability.daytimeSaturday}
                      onChange={this.handleChangeDaytime}
                    />
                    <span>Saturday</span>
                  </label>

                  <label>
                    <input
                      type="checkbox"
                      name="daytimeSunday"
                      checked={this.state.daytimeAvailability.daytimeSunday}
                      onChange={this.handleChangeDaytime}
                    />
                    <span>Sunday</span>
                  </label>
                </div>
              </div>
              <div>
                <legend>
                  Nighttime Availability
                  <span className="careers__availability--times">
                    (5pm-12am)
                  </span>{" "}
                  *
                </legend>
                <div className="careers__availability--day">
                  <label>
                    <input
                      type="checkbox"
                      name="nighttimeMonday"
                      checked={this.state.nighttimeAvailability.nighttimeMonday}
                      onChange={this.handleChangeNighttime}
                    />
                    <span>Monday</span>
                  </label>

                  <label>
                    <input
                      type="checkbox"
                      name="nighttimeTuesday"
                      checked={
                        this.state.nighttimeAvailability.nighttimeTuesday
                      }
                      onChange={this.handleChangeNighttime}
                    />
                    <span>Tuesday</span>
                  </label>

                  <label>
                    <input
                      type="checkbox"
                      name="nighttimeWednesday"
                      checked={
                        this.state.nighttimeAvailability.nighttimeWednesday
                      }
                      onChange={this.handleChangeNighttime}
                    />
                    <span>Wednesday</span>
                  </label>

                  <label>
                    <input
                      type="checkbox"
                      name="nighttimeThursday"
                      checked={
                        this.state.nighttimeAvailability.nighttimeThursday
                      }
                      onChange={this.handleChangeNighttime}
                    />
                    <span>Thursday</span>
                  </label>

                  <label>
                    <input
                      type="checkbox"
                      name="nighttimeFriday"
                      checked={this.state.nighttimeAvailability.nighttimeFriday}
                      onChange={this.handleChangeNighttime}
                    />
                    <span>Friday</span>
                  </label>

                  <label>
                    <input
                      type="checkbox"
                      name="nighttimeSaturday"
                      checked={
                        this.state.nighttimeAvailability.nighttimeSaturday
                      }
                      onChange={this.handleChangeNighttime}
                    />
                    <span>Saturday</span>
                  </label>

                  <label>
                    <input
                      type="checkbox"
                      name="nighttimeSunday"
                      checked={this.state.nighttimeAvailability.nighttimeSunday}
                      onChange={this.handleChangeNighttime}
                    />
                    <span>Sunday</span>
                  </label>
                </div>
              </div>
            </div>

            {/***********************************************************/}
            {/*********************** Employment Types ******************/}
            {/***********************************************************/}
            <div>
              <legend>Type of employment desired. *</legend>
              <div className="careers__employment">
                <label>
                  <input
                    type="checkbox"
                    name="fullTime"
                    checked={this.state.typeOfEmployment.fullTime}
                    onChange={this.handleChangeEmploymentType}
                  />
                  <span>Full Time</span>
                </label>

                <label>
                  <input
                    type="checkbox"
                    name="partTime"
                    checked={this.state.typeOfEmployment.partTime}
                    onChange={this.handleChangeEmploymentType}
                  />
                  <span>Part Time</span>
                </label>

                <label>
                  <input
                    type="checkbox"
                    name="seasonal"
                    checked={this.state.typeOfEmployment.seasonal}
                    onChange={this.handleChangeEmploymentType}
                  />
                  <span>Seasonal</span>
                </label>

                <label>
                  <input
                    type="checkbox"
                    name="temporary"
                    checked={this.state.typeOfEmployment.temporary}
                    onChange={this.handleChangeEmploymentType}
                  />
                  <span>Temporary</span>
                </label>
              </div>
            </div>

            {/***********************************************************/}
            {/*********************** Applied Before ********************/}
            {/***********************************************************/}
            <div>
              <legend>Have you ever applied to Saigon Alley? *</legend>
              <div className="careers__haveApplied">
                <label>
                  <input
                    type="radio"
                    name="haveApplied"
                    required
                    value="yes"
                    checked={this.state.haveApplied === "yes"}
                    onChange={this.handleChange}
                  />
                  <span>Yes</span>
                </label>

                <label>
                  <input
                    type="radio"
                    name="haveApplied"
                    required
                    value="no"
                    checked={this.state.haveApplied === "no"}
                    onChange={this.handleChange}
                  />
                  <span>No</span>
                </label>
              </div>
            </div>

            {/***********************************************************/}
            {/**************** Legal Status to Work in US ***************/}
            {/***********************************************************/}
            <div>
              <legend>
                Are you Legally allowed to work in the United States? *
              </legend>
              <div className="careers__legallyAllowedToWorkInUS">
                <label>
                  <input
                    type="radio"
                    name="legallyAllowedToWorkInUS"
                    required
                    value="yes"
                    checked={this.state.legallyAllowedToWorkInUS === "yes"}
                    onChange={this.handleChange}
                  />
                  <span>Yes</span>
                </label>

                <label>
                  <input
                    type="radio"
                    name="legallyAllowedToWorkInUS"
                    required
                    value="no"
                    checked={this.state.legallyAllowedToWorkInUS === "no"}
                    onChange={this.handleChange}
                  />
                  <span>No</span>
                </label>
              </div>
            </div>
            {/***********************************************************/}
            {/******************** Education History ********************/}
            {/***********************************************************/}
            <div>
              <legend>Education:</legend>
              <div className="careers__highschool">
                <div>
                  <input
                    type="text"
                    placeholder="High School Name"
                    name="highschoolName"
                    value={this.state.highschoolName}
                    onChange={this.handleChange}
                  />
                </div>
                <div>
                  <input
                    type="text"
                    placeholder="High School Location"
                    name="highschoolLocation"
                    value={this.state.highschoolLocatioin}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div>
                <legend>High School Status*</legend>

                <div className="careers__highschoolStatus">
                  <label>
                    <input
                      type="radio"
                      name="highschoolStatus"
                      required
                      value="Currently Attending"
                      checked={
                        this.state.highschoolStatus === "Currently Attending"
                      }
                      onChange={this.handleChange}
                    />
                    <span>Currently attending</span>
                  </label>

                  <label>
                    <input
                      type="radio"
                      name="highschoolStatus"
                      required
                      value="Graduated"
                      checked={this.state.highschoolStatus === "Graduated"}
                      onChange={this.handleChange}
                    />
                    <span>Graduated</span>
                  </label>

                  <label>
                    <input
                      type="radio"
                      name="highschoolStatus"
                      required
                      value="Did Not Graduated"
                      checked={
                        this.state.highschoolStatus === "Did Not Graduated"
                      }
                      onChange={this.handleChange}
                    />
                    <span>Did not graduate</span>
                  </label>
                </div>
              </div>

              <div className="careers__college">
                <div>
                  <input
                    type="text"
                    placeholder="College Name"
                    name="collegeName"
                    value={this.state.collegeName}
                    onChange={this.handleChange}
                  />
                </div>
                <div>
                  <input
                    type="text"
                    placeholder="College Location"
                    name="collegeLocation"
                    value={this.state.collegeLocation}
                    onChange={this.handleChange}
                  />
                </div>
              </div>

              <div>
                <legend>College Status*</legend>
                <div className="careers__collegeStatus">
                  <label>
                    <input
                      type="radio"
                      name="collegeStatus"
                      required
                      value="Currently Attending"
                      checked={
                        this.state.collegeStatus === "Currently Attending"
                      }
                      onChange={this.handleChange}
                    />
                    <span>Currently attending</span>
                  </label>

                  <label>
                    <input
                      type="radio"
                      name="collegeStatus"
                      required
                      value="Graduated"
                      checked={this.state.collegeStatus === "Graduated"}
                      onChange={this.handleChange}
                    />
                    <span>Graduated</span>
                  </label>

                  <label>
                    <input
                      type="radio"
                      name="collegeStatus"
                      required
                      value="Did Not Graduated"
                      checked={this.state.collegeStatus === "Did Not Graduated"}
                      onChange={this.handleChange}
                    />
                    <span>Did not graduate</span>
                  </label>
                </div>
              </div>
            </div>

            {/***********************************************************/}
            {/********************** Work History ***********************/}
            {/***********************************************************/}
            <div>
              <div>
                <legend>Employment History:</legend>
                <p>
                  Provide your employment history starting with the company for
                  which you have most recently worked.
                </p>
              </div>

              {/******************** Company 1 *******************/}
              <div className="careers__work--company">
                <legend>Company 1</legend>
                <div>
                  <label>
                    <input
                      type="text"
                      size="20"
                      placeholder="Company Name"
                      name="firstCompanyName"
                      title="Company Name"
                      value={this.state.firstCompanyName}
                      onChange={this.handleChange}
                    />
                  </label>

                  <label>
                    <input
                      type="text"
                      size="20"
                      required={this.state.firstCompanyName ? true : false}
                      name="firstJobTitle"
                      title="Job Title"
                      placeholder="Job Title"
                      value={this.state.firstJobTitle}
                      onChange={this.handleChange}
                    />
                  </label>

                  <div>
                    <label for="StartDate1">Start Date:</label>
                    <input
                      type="date"
                      size="20"
                      required={this.state.firstCompanyName ? true : false}
                      title="Start Date 1"
                      name="firstJobStartDate"
                      value={this.state.firstJobStartDate}
                      onChange={this.handleChange}
                    />
                  </div>

                  <div>
                    <label for="EndDate1">End Date:</label>
                    <input
                      type="date"
                      size="20"
                      required={this.state.firstCompanyName ? true : false}
                      title="End Date 1"
                      name="firstJobEndDate"
                      value={this.state.firstJobEndDate}
                      onChange={this.handleChange}
                    />
                  </div>

                  <label>
                    <legend>Responsibilities:</legend>
                    <textarea
                      name="firstJobResponsibilities"
                      title="Responsibilities"
                      required={this.state.firstCompanyName ? true : false}
                      cols="50"
                      rows="3"
                      value={this.state.firstJobResponsibilities}
                      onChange={this.handleChange}
                    ></textarea>
                  </label>

                  <label>
                    <legend>Reason for Leaving:</legend>
                    <textarea
                      name="firstJobReasonForLeaving"
                      title="Reason for Leaving"
                      required={this.state.firstCompanyName ? true : false}
                      cols="50"
                      rows="3"
                      value={this.state.firstJobReasonForLeaving}
                      onChange={this.handleChange}
                    ></textarea>
                  </label>
                </div>
              </div>

              {/******************* Company 2 *******************/}
              <div className="careers__work--company">
                <legend>Company 2</legend>
                <div>
                  <label>
                    <input
                      type="text"
                      size="20"
                      placeholder="Company Name"
                      name="secondCompanyName"
                      title="Company Name"
                      value={this.state.secondCompanyName}
                      onChange={this.handleChange}
                    />
                  </label>

                  <label>
                    <input
                      type="text"
                      size="20"
                      required={this.state.secondCompanyName ? true : false}
                      name="secondJobTitle"
                      title="Job Title"
                      placeholder="Job Title"
                      value={this.state.secondJobTitle}
                      onChange={this.handleChange}
                    />
                  </label>

                  <div>
                    <label for="StartDate1">Start Date:</label>
                    <input
                      type="date"
                      size="20"
                      required={this.state.secondCompanyName ? true : false}
                      title="Start Date 1"
                      name="secondJobStartDate"
                      value={this.state.secondJobStartDate}
                      onChange={this.handleChange}
                    />
                  </div>

                  <div>
                    <label for="EndDate1">End Date:</label>
                    <input
                      type="date"
                      size="20"
                      required={this.state.secondCompanyName ? true : false}
                      title="End Date 1"
                      name="secondJobEndDate"
                      value={this.state.secondJobEndDate}
                      onChange={this.handleChange}
                    />
                  </div>

                  <label>
                    <legend>Responsibilities:</legend>
                    <textarea
                      name="secondJobResponsibilities"
                      title="Responsibilities"
                      required={this.state.secondCompanyName ? true : false}
                      cols="50"
                      rows="3"
                      value={this.state.secondJobResponsibilities}
                      onChange={this.handleChange}
                    ></textarea>
                  </label>

                  <label>
                    <legend>Reason for Leaving:</legend>
                    <textarea
                      name="secondJobReasonForLeaving"
                      title="Reason for Leaving"
                      required={this.state.secondCompanyName ? true : false}
                      cols="50"
                      rows="3"
                      value={this.state.secondJobReasonForLeaving}
                      onChange={this.handleChange}
                    ></textarea>
                  </label>
                </div>
              </div>

              {/******************* Company 3 *******************/}
              <div className="careers__work--company">
                <legend>Company 3</legend>
                <div>
                  <label>
                    <input
                      type="text"
                      size="20"
                      placeholder="Company Name"
                      name="thirdCompanyName"
                      title="Company Name"
                      value={this.state.thirdCompanyName}
                      onChange={this.handleChange}
                    />
                  </label>

                  <label>
                    <input
                      type="text"
                      size="20"
                      required={this.state.thirdCompanyName ? true : false}
                      name="thirdJobTitle"
                      title="Job Title"
                      placeholder="Job Title"
                      value={this.state.thirdJobTitle}
                      onChange={this.handleChange}
                    />
                  </label>

                  <div>
                    <label for="StartDate1">Start Date:</label>
                    <input
                      type="date"
                      size="20"
                      required={this.state.thirdCompanyName ? true : false}
                      title="Start Date 1"
                      name="thirdJobStartDate"
                      value={this.state.thirdJobStartDate}
                      onChange={this.handleChange}
                    />
                  </div>

                  <div>
                    <label for="EndDate1">End Date:</label>
                    <input
                      type="date"
                      size="20"
                      required={this.state.thirdCompanyName ? true : false}
                      title="End Date 1"
                      name="thirdJobEndDate"
                      value={this.state.thirdJobEndDate}
                      onChange={this.handleChange}
                    />
                  </div>

                  <label>
                    <legend>Responsibilities:</legend>
                    <textarea
                      name="thirdJobResponsibilities"
                      title="Responsibilities"
                      required={this.state.thirdCompanyName ? true : false}
                      cols="50"
                      rows="3"
                      value={this.state.thirdJobResponsibilities}
                      onChange={this.handleChange}
                    ></textarea>
                  </label>

                  <label>
                    <legend>Reason for Leaving:</legend>
                    <textarea
                      name="thirdJobReasonForLeaving"
                      title="Reason for Leaving"
                      required={this.state.thirdCompanyName ? true : false}
                      cols="50"
                      rows="3"
                      value={this.state.thirdJobReasonForLeaving}
                      onChange={this.handleChange}
                    ></textarea>
                  </label>
                </div>
              </div>
            </div>
            {/***********************************************************/}
            {/********************** Authorization ***********************/}
            {/***********************************************************/}
            <div>
              <h2>Authorization</h2>
              <hr />
              <p>
                "I certify that the facts contained in this application are
                complete to the best of my knowledge and understand that, if
                hired, falsified statements on this application shall be grounds
                for dismissal. I authorize investigation of all statements
                contained herein the company references and employers listed on
                this application and my resume to give any and all information
                concerning my previous employment work and any pertinent
                information they may have, personal or otherwise, and release
                the company of all liability for any damage that may result from
                utilization of such information. This waiver does not permit the
                release or use of disability related medical information in a
                manner prohibited by the Americans with Disabilities Act (ADA)
                and other relevant federal and state laws. By entering my full
                name in the box below as an electronic signature, I hereby
                certify that the information entered above is correct to the
                best of my knowledge, and that falsifying information shall be
                grounds for disqualification from or termination of employment."
                *
              </p>
              <div className="careers__signature">
                <label>
                  <legend>Signature:</legend>
                  <input
                    type="text"
                    required
                    name="signature"
                    value={this.state.signature}
                    onChange={this.handleChange}
                  />
                </label>

                <label>
                  <legend>Date:</legend>
                  <input
                    type="date"
                    required
                    size="20"
                    title="signDate"
                    name="signDate"
                    value={this.state.signDate}
                    onChange={this.handleChange}
                  />
                </label>
              </div>
            </div>
            {/***********************************************************/}
            {/********************** Button Submit **********************/}
            {/***********************************************************/}
            <div>
              <button className="submit application">Send Application</button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default withRouter(Form);
