import React, { Component } from "react";
import "./NavMobile.css";
import "materialize-css/dist/css/materialize.min.css";
import M from "materialize-css/dist/js/materialize.min.js";
import { Link } from "react-router-dom";

class NavMobile extends Component {
  componentDidMount() {
    var elem = document.querySelector(".sidenav");
    M.Sidenav.init(elem, {
      edge: "left",
      inDuration: 250,
    });
  }

  render() {
    return (
      <div className="nav-mobile">
        <a
          href="/"
          data-target="mobile-demo"
          id="mySideNav"
          className="sidenav-trigger"
        >
          <p style={{ display: "none" }}>Home</p>
          <i className="fas fa-bars"></i>
        </a>
        <a
          href="https://www.toasttab.com/saigon-alley-kitchen-bar/v2/online-order#!/"
          target="_blank"
          without="true"
          rel="noopener noreferrer"
          className="order-online"
        >
          <p>
            <span className="order">Order</span> Midtown
          </p>
        </a>
        <a
          href="http://toasttab.com/saigon-alley-natomas-4630-natomas-boulevard-xsdqh"
          target="_blank"
          without="true"
          rel="noopener noreferrer"
          className="order-online order-online-right"
        >
          <p>
            <span className="order">Order</span> Natomas
          </p>
        </a>
        <ul className="sidenav" id="mobile-demo">
          <li>
            <Link to="/">Home</Link>
          </li>
          {/* <li>
                        <Link to="/about">About</Link>
                    </li> */}
          <li>
            <Link to="/midtown">Midtown</Link>
          </li>
          <li>
            <Link to="/natomas">Natomas</Link>
          </li>
          <li>
            <Link to="/gallery">Gallery</Link>
          </li>
          <li>
            <Link to="/careers">Careers</Link>
          </li>
          <li>
            <Link to="/contact">Contact</Link>
          </li>
          <li>
            <Link to="/catering">Catering Orders</Link>
          </li>
          <li>
            <a
              href="https://os.resy.com/portal/booking/party"
              target="_blank"
              without="true"
              rel="noopener noreferrer"
              className="order"
            >
              Reservation
            </a>
          </li>
          <li>
            <a
              href="https://www.toasttab.com/saigon-alley-kitchen-bar/v2/online-order#!/"
              target="_blank"
              without="true"
              rel="noopener noreferrer"
              className="order"
            >
              Order (Midtown)
            </a>
          </li>
          <li>
            <a
              href="https://toasttab.com/saigon-alley-natomas-4630-natomas-boulevard-xsdqh"
              className="order toast-tab"
              target="_blank"
              without="true"
              rel="noopener noreferrer"
            >
              Order (Natomas)
            </a>
          </li>
        </ul>
      </div>
    );
  }
}

export default NavMobile;
