import React from 'react';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import Routes from './routes';
import NavMobile from '../src/components/Nav/NavMobile/NavMobile';
import Scroll from '../src/components/ScrollToTop/ScrollToTop';
import Footer from '../src/components/Footer/Footer';
import Store from './store'
import Alert from './components/Alert'
function App() {
  return (
    <div>
    
    <Store>
   
      <Scroll scrollStepInPx='50' delayInMs='16.66'/>
      <BrowserRouter> 
      {/* <Alert/> */}
      <NavMobile />
        <Routes />
      </BrowserRouter>
      <Footer />
      </Store>
    </div>
  );
}

export default App;
