
import React, { Component } from "react";
import "./EmailForm.css";
import { withRouter } from 'react-router';

class EmailForm extends Component {
    constructor(props){
        super(props);
        this.state = {
          message: '',
        }
        this.handleSubmit = this.handleSubmit.bind(this);
      }
      handleSubmit() {
          alert('Thank you for being part of the community! You are now added to our email list. You will be notified on any promotions, discounts, and any new cuisines that we may add to our menu in the future.')
          this.props.history.push('/201');
      }

    render() {
        return (
            <div className="promotions">
               <div id="index-banner" className="header-promotions">
                    <div className="header-title">
                        <br />
                        <h1 className="center">Promotions / Email Listing</h1>
                    </div>
                </div>

                <div className="container next section">
                    <form method="POST" action="send" onSubmit={this.handleSubmit} >  
                        <h5 className="center">For future promotions about our discounts and other news, please provide your name and email below: </h5>
                   
                        <div class="row">
                            <div class="input-field col s12 m4">
                                <input id="first_name" type="text" class="validate" name="fullName"/>
                                <label for="first_name">Full Name</label>
                            </div>
                            <div class="input-field col s12 m4">
                                <input id="email" type="email" class="validate" name="email" />
                                <label for="email">Email</label>
                            </div>
                            <div class="col s12 m4">
                                <button className="submit">Submit</button>
                            </div>
                        </div>
                    </form>
              </div>
            </div>
           
        );
    }
}

export default withRouter(EmailForm);