import React, {useState} from 'react';
import Modal from 'react-responsive-modal';
import './Popup.css'

const PopupTwo = () => {
    const [isOpen, setIsOpen] = useState(false)
    const [spinner, setSpinner] = useState({
        loading: true
    })

    const hideSpinner = () => {
        setSpinner({
            loading: false
        })
    }

    const onOpenModal = () => {
        setIsOpen(true)
    }

    const onCloseModal = () => {
        setIsOpen(false)
    }

    return (
        <>
                    <a className="order" href="https://toasttab.com/saigon-alley-natomas-4630-natomas-boulevard-xsdqh" target="_blank">ORDER (Natomas)</a>
                {/* <a className="order" onClick={onOpenModal}>ORDER (NATOMAS)</a>
                <Modal open={isOpen} onClose={onCloseModal} center>
                {
                    spinner.loading ? (
                        <div className="loader">
                        <svg class="svgLoader" viewBox="0 0 100 100" width="10em" height="10em">
                            <path ng-attr-d="{{config.pathCmd}}" ng-attr-fill="{{config.color}}" stroke="none" d="M10 50A40 40 0 0 0 90 50A40 42 0 0 1 10 50" fill="rgba(255, 255, 255, 0.3)" transform="rotate(179.719 50 51)"><animateTransform attributeName="transform" type="rotate" calcMode="linear" values="0 50 51;360 50 51" keyTimes="0;3" dur="3s" begin="0s" repeatCount="indefinite"></animateTransform></path>
                        </svg>
                        </div>
                    ) : null
                }
                <iframe onLoad={hideSpinner} src="https://toasttab.com/saigon-alley-natomas-4630-natomas-boulevard-xsdqh" className="order-frame"></iframe>
                </Modal> */}
            </>
    );
};

export default PopupTwo;