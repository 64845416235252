import React, { Component } from "react";
import "./MenuList.css";
import M from "materialize-css";
import ReactTooltip from "react-tooltip";

export default class CateringMenu extends Component {
  componentDidMount() {
    M.Tabs.init(this.Tabs, {
      swipeable: false,
    });
  }

  render() {
    return (
      <div className="menu-list">
        {/* <ReactTooltip type="dark" effect="solid" /> */}
        <div id="index-banner" className="header-menu">
          <div className="header-title">
            <br />
            <h1 className="center">Catering Orders</h1>
          </div>
        </div>

        <div className="container next">
          <div className="row"></div>
        </div>

        <div id="menu">
          <p style={{ textAlign: "center" }} className="catering-menu-text">
            Looking for catering options or have any questions regarding private
            events, please email{" "}
            <a
              style={{ color: "black", fontWeight: "bold" }}
              href="mailto: events@saigonalley.com"
            >
              events@saigonalley.com
            </a>
            .
          </p>
        </div>

        <div className="next-section"></div>
      </div>
    );
  }
}
