import React, { Component } from "react";
import NavTwo from "../Nav/NavTwo/NavTwo";
import Form from "../Form/Form";
import Seo from "../Seo";

class Careers extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <Seo
          title="Careers"
          content="Apply to Saigon Alley Kitchen & Bar"
          url="/careers"
        />
        <NavTwo />
        <Form />
      </div>
    );
  }
}

export default Careers;
