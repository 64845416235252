import React, { Component } from "react";
import NavTwo from '../Nav/NavTwo/NavTwo';
import EmailForm from '../EmailForm/EmailForm';
import Seo from "../Seo";

class EmailList extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  
  render() {
    return (
        <div>
          <NavTwo />
          <EmailForm />
        </div>
    );
  }
}

export default EmailList;