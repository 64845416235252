import React, { Component } from "react";
import NavTwo from '../Nav/NavTwo/NavTwo';
import ContactSection from '../ContactSection/ContactSection';
import Seo from "../Seo";

class Contact extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  
  render() {
    return (
        <div>
          <Seo
          title="Contact"
          content="Contact Saigon Alley Kitchen & Bar" 
          url="/contact"
          />
          <NavTwo />
          <ContactSection 
            // onSubmit={this.submit}
            // initialValues = {this.getInitialValues()}
            />
        </div>
    );
  }
}

export default Contact;