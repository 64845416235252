import React, { Component } from "react";
import NavTwo from "../Nav/NavTwo/NavTwo";
import MenuList2 from "../MenuList/MenuList2";
import Seo from "../Seo";

class Natomas extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <Seo
          title="Saigon Alley Natomas Menu"
          content="saigon alley menu. vietnamese. fusion. kitchen. bar. pub"
          url="/natomas"
        />
        <NavTwo />
        <MenuList2 />
      </div>
    );
  }
}

export default Natomas;
