import React, { Component } from "react";
import "./ParallaxOne.css";
import skewers from "../../../images/food/12.jpg";
import lineOfBeers from "../../../images/food/lineofbeers.jpg";
import pho from "../../../images/food/43.jpg";
import noodles from "../../../images/food/42.jpg";
import sandwichPlate from "../../../images/food/sandwich-plate.jpg";
import FrontInterior from "../../../images/interior/1.jpg";
import SA from "../../../images/sgw.png";
import Logo from "../../../images/salogo.png";
import Beer from "../../../images/food/1.jpg";
import Cocktail from "../../../images/food/cocktail.jpg";
// import PDF from "../../../images/saigon-alley-menu.pdf";
import BackgroundSlideshow from "react-background-slideshow";
import M from "materialize-css";
import { Link } from "react-router-dom";
import Popup from "../../Popup/Popup";
import Reservation from "../../Popup/Reservation";
import ReservationPopup from "../../ReservationPopup/ReservationPopup";
import Seo from "../../Seo";
import PopupTwo from "../../Popup/PopupTwo";

export default class Parallax1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      images: [lineOfBeers, pho, noodles],
      selectedImage: lineOfBeers,
      section2:
        "Our approach is simple - use the freshest ingredients to create some of the most flavorful poke bowls, sushi burritos, Hawaiian plates and salads. Being in the heart of California’s Agricultural hub, we strive to bring food that is locally and responsibly sourced while ethically sustainable. We only use top quality ingredients dedicated to the art and enjoyment of your creations! With our ever-evolving community, we are inspired and excited to bring progressive and spontaneous seasonal ingredients. Focused on millennial food trends with classic culinary style, it allows us to create some of the best food experiences you have yet to taste! ",
      section3:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer  industry. Lorem Ipsum has been the...",
      Beer: "We provide a selection of special cocktails centered on Asian inspired flavors that prides us to be different.",
      Cocktail:
        "Come and enjoy some of the beers that we have to offer with a wide range of different cultural beers to choose from.",
      team: "Saigon Alley Food and Kitchen created a menu that stays true to the Vietnamese roots. Each of our dish offers a taste of Vietnam with an American flare that keeps customers coming back for more. While they are flavorful, we also utilize fresh ingredients to deliver delicious and quality food to the table.",
      reservation:
        "For your convenience, place an order online during business hours and we will get your order ready as soon as possible. If you would like to reserve a table, please give us a call. Thank you, we look forward to seeing you.",
    };
  }

  componentDidMount() {
    let elems = document.querySelectorAll(".parallax");
    M.Parallax.init(elems, {});
  }

  render() {
    return (
      <div className="parallax-one" id="return-to-top">
        <Seo
          title="Saigon Alley Kitchen & Bar"
          content='AT SAIGON ALLEY WE STRIVE TO PROVIDE YOU AN "AN NHAU" EXPERIENCE WITH OUR BAR FORWARD MENU CONSISTING OF VIETNAMESE INSPIRED CRAFT COCKTAILS AND TAPAS.'
          url="/"
        />
        <ReservationPopup />
        <div id="index-banner" className="parallax-container">
          <div className="section no-pad-bot top">
            <div className="container">
              {/* <div className="bg">
                                </div> */}
              <div className="row">
                <div className="social">
                  <a
                    href="https://www.facebook.com/saigonalleysac"
                    target="_blank"
                    without="true"
                    rel="noopener noreferrer"
                  >
                    <p style={{ display: "none" }}>facebook</p>
                    <i className="fab fa-facebook-f"></i>
                  </a>
                  {/* <Link to="" target="_blank" without="true" rel="noopener noreferrer"><i className="fab fa-twitter"></i></Link> */}
                  <a
                    href="https://www.instagram.com/saigonalley.sac/?igshid=YmMyMTA2M2Y%3D"
                    target="_blank"
                    without="true"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    <p style={{ display: "none" }}>instagram</p>
                    <i className="fab fa-instagram"></i>
                  </a>
                  <a
                    href="https://www.yelp.com/biz/saigon-alley-kitchen-bar-sacramento"
                    target="_blank"
                    without="true"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    <p style={{ display: "none" }}>yelp</p>
                    <i className="fab fa-yelp"></i>
                  </a>
                </div>
              </div>
              <div className="header">
                <div className="row center sa">
                  <img
                    width="auto"
                    height="auto"
                    className="logo responsive-img"
                    src={SA}
                    alt="logo"
                  />
                </div>

                <div className="row center links shift">
                  <ul>
                    {/* <li><Link to="/">HOME</Link></li> */}
                    {/* <li><Link to="/about">ABOUT</Link></li> */}
                    <li>
                      <Link to="/midtown">MIDTOWN</Link>
                    </li>
                    <li>
                      <Link to="/natomas">NATOMAS</Link>
                    </li>
                    <li>
                      <Link to="/catering">CATERING ORDERS</Link>
                    </li>
                    <li>
                      <Link to="/gallery">GALLERY</Link>
                    </li>
                    {/* <li><Link to="/careers">CAREERS</Link></li> */}
                    <li>
                      <Link to="/contact">CONTACT</Link>
                    </li>
                    {/* <li>
                                            <a href="https://os.resy.com/portal/booking/party" target="_blank" without="true" rel="noopener noreferrer">Reservation</a>
                                        </li> */}
                    <br />
                    <br />
                    <li>
                      <Reservation />
                    </li>
                    <li>
                      <Popup />
                    </li>
                    <li>
                      <PopupTwo />
                    </li>
                    {/* <li><a href="order-online" to="https://www.toasttab.com/saigon-alley-kitchen-bar/v2/online-order#!/" target="_blank" without="true" rel="noopener noreferrer">ORDER ONLINE</a></li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <BackgroundSlideshow
            className="parallax img slideshow"
            images={[pho, noodles, lineOfBeers]}
          />
        </div>

        <div
          className="next next-section"
          ref={(section) => {
            this.Next = section;
          }}
        >
          <div className="parallax-container section-2">
            <div className="section">
              <div className="container">
                <div className="row">
                  <h1>Saigon Alley Kitchen & Bar</h1>
                  AT SAIGON ALLEY WE STRIVE TO PROVIDE YOU AN "AN NHAU"
                  EXPERIENCE WITH OUR BAR FORWARD MENU CONSISTING OF VIETNAMESE
                  INSPIRED CRAFT COCKTAILS AND TAPAS. OUR PLATES ARE MEANT TO BE
                  SHARED AND ENJOYED WITH FRIENDS AND FAMILY -FOR GATHERINGS-
                  REMINISCENT OF NIGHT DRINKING FOUND IN THE ALLEYWAYS OF
                  VIETNAM.
                  <br />
                  <br />
                  "MOT, HAI, BA- GIO!"
                </div>
              </div>
            </div>

            <div className="parallax img2">
              <img width="auto" height="auto" src={FrontInterior} alt="bg2" />
            </div>
          </div>
        </div>
        <div className="section-4">
          <div className="grid row">
            <div className="col s12 m6">
              <figure className="effect-lexi">
                <img width="auto" height="auto" src={Beer} alt="img12" />
                <figcaption>
                  <h2>
                    <span>BEERS</span>
                  </h2>
                  <p>{this.state.Cocktail}</p>
                  {/* <Link to="/Beer">View more</Link> */}
                </figcaption>
              </figure>
            </div>
            <div className="col s12 m6">
              <figure className="effect-lexi">
                <img
                  width="auto"
                  height="auto"
                  className="cocktail-img"
                  src={Cocktail}
                  alt="img03"
                />
                <figcaption>
                  <h2>
                    COCK<span>TAILS</span>
                  </h2>

                  <p>{this.state.Beer}</p>
                  {/* <Link to="/Cocktail">View more</Link> */}
                </figcaption>
              </figure>
            </div>
          </div>
        </div>

        <div className="parallax-container valign-wrapper section-5">
          <div className="section no-pad-bot">
            <div className="container">
              <div className="row">
                <div className="col s12 m6">
                  <h3>Vietnamese Cuisine</h3>
                  <br />
                  <p>{this.state.team}</p>
                  <br />
                  <Link to="/menu" className="menu-btn">
                    View Menu
                  </Link>
                  {/* <br/>
                                    <Link className="menu-btn" to={PDF} download>Download Menu</Link> */}
                </div>
                <div className="col s12 m6"></div>
              </div>
            </div>
          </div>
          <div className="parallax">
            <img width="auto" height="auto" src={skewers} alt="fried chicken" />
          </div>
        </div>
        <div className="parallax-container valign-wrapper section-6">
          <div className="section">
            <div className="container">
              <div className="row">
                <div className="col s12 m6"></div>

                <div className="col s12 m6">
                  <h3>ORDER NOW</h3>
                  <br />
                  <p>{this.state.reservation}</p>
                  <p>(916) 758-6934 (Midtown)</p>
                  <p>(916) 515-8184 (Natomas)</p>
                  <br />
                  <Popup />
                  <br />
                  <PopupTwo />
                  <a
                    href="https://www.toasttab.com/saigon-alley-kitchen-bar/v2/online-order#!/"
                    className="order toast-tab"
                    target="_blank"
                    without="true"
                    rel="noopener noreferrer"
                  >
                    Order Online (Midtown)
                  </a>
                  <a
                    href="https://toasttab.com/saigon-alley-natomas-4630-natomas-boulevard-xsdqh"
                    className="order toast-tab"
                    target="_blank"
                    without="true"
                    rel="noopener noreferrer"
                  >
                    Order Online (Natomas)
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="parallax">
            <img
              width="auto"
              height="auto"
              src={sandwichPlate}
              alt="sandwich"
            />
          </div>
        </div>
      </div>
    );
  }
}
