
import React, { Component } from "react";
import "./Error.css";

class Error extends Component {

    render() {
        return (
            <div className="error">
               <div id="index-banner" className="header-error">
                    <div className="header-title">
                        <br />
                        <h1 className="center">Sorry!</h1>
                        <p>This page is not found</p>
                    </div>
                </div>

                <div className="next">
                    
              </div>
            </div>
           
        );
    }
}

export default Error;