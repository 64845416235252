import React, { Component } from "react";
import NavTwo from '../Nav/NavTwo/NavTwo';
import MenuList from '../MenuList/MenuList';
import Seo from "../Seo";

class Menu extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <Seo
          title="Saigon Alley Menu"
          content="saigon alley menu. vietnamese. fusion. kitchen. bar. pub"
          url="/menu"
        />
        <NavTwo />
        <MenuList />
      </div>
    );
  }
}

export default Menu;