import React, { Component } from "react";
import NavTwo from '../Nav/NavTwo/NavTwo';
import Error from '../Error/Error';
import Seo from "../Seo";

class NotFound extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  
  render() {
    return (
        <div>
          <Seo 
            title="Page not found"
            content="This page is not available"
          />
          <NavTwo />
            <Error />
        </div>
    );
  }
}

export default NotFound;