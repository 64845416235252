import React, { Component } from "react";
import "./Footer.css";

export default class Footer extends Component {
  render() {
    return (
      <div className="footer center">
        {/* <div className="footer-header">SAIGON ALLEY INFORMATION</div> */}
        <footer className="footer-distributed">
          <div className="footer-left">
            <div className="footer-icons">
              <p>SOCIAL MEDIA</p>
              <a
                href="https://www.facebook.com/saigonalleysac"
                target="_blank"
                rel="noopener noreferrer"
              >
                <p style={{ display: "none" }}>facebook</p>
                <i className="fab fa-facebook-f"></i>
              </a>
              {/* <a href="" target="_blank"><i className="fab fa-twitter"></i></a> */}
              <a
                href="https://www.instagram.com/saigonalley.sac/?igshid=YmMyMTA2M2Y%3D"
                target="_blank"
                rel="noopener noreferrer"
              >
                <p style={{ display: "none" }}>instagram</p>
                <i className="fab fa-instagram"></i>
              </a>
              <a
                href="https://www.yelp.com/biz/saigon-alley-kitchen-bar-sacramento"
                target="_blank"
                rel="noopener noreferrer"
              >
                <p style={{ display: "none" }}>yelp</p>
                <i className="fab fa-yelp"></i>
              </a>
            </div>
            <br />
            <p className="promotions">OTHER</p>
            <br />
            <a className="careers-btn" href="/careers">
              Employment Opportunities
            </a>
            <br />
            <a className="privacy-btn" href="/privacy">
              Private Policy
            </a>
          </div>
          <div className="footer-center">
            <span>Hours of Operation</span>
            <div style={{ paddingTop: 12 }}>
              <i className="fa fa-map-marker"></i>
              <p>
                1801 L Street Suite 50,
                <br />
                Sacramento, CA 95811
                <br />
                <br />
                4630 Natomas Blvd #150,
                <br />
                Sacramento, CA 95835
              </p>
            </div>
            <br />
            <div>
              <i className="fa fa-phone"></i>
              <p>
                (916) 758-6934 (Midtown)
                <br />
                (916) 515-8184 (Natomas)
              </p>
            </div>
            <br />
            <div>
              <i className="fa fa-envelope"></i>
              <p>
                <a href="mailto:info@saigonalley.com">info@saigonalley.com</a>
                <br />
                <a href="mailto:careers@saigonalley.com">
                  careers@saigonalley.com
                </a>
              </p>
            </div>
          </div>
          <div className="footer-right">
            <p className="footer-company-about">
              <span>Information</span>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div>
                  Midtown:
                  <br />
                  <b>Sunday - Thursday:</b>
                  <br />
                  11:00am - 8:30pm
                  <br />
                  <br />
                  <b>Friday - Saturday:</b>
                  <br />
                  11:00am - 9:30pm
                  <br />
                  <br />
                  Happy Hour:
                  <br />
                  <b>Monday - Friday</b>
                  <br />
                  3:00pm - 6:00pm
                </div>
                <div style={{ marginLeft: 16 }}>
                  Natomas:
                  <br />
                  <b>Sunday - Thursday:</b>
                  <br />
                  11:00am- 8:30pm
                  <br />
                  <br />
                  <b>Friday - Saturday:</b>
                  <br />
                  11:00am-9:30pm
                  <br />
                  <br />
                </div>
              </div>
            </p>
          </div>
        </footer>
        {/* <GoogleMap /> */}
        <div className="footer-bottom">
          <div className="row">
            <div className="col s12">
              <p className="footer-company-name">
                © 2023 <a href="/">Saigon Alley Kitchen & Bar</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
