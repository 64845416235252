import React, { Component } from "react";
import NavTwo from '../Nav/NavTwo/NavTwo';
import CateringMenu from '../MenuList/CateringMenu';
import Seo from "../Seo";

class Menu extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <Seo
          title="Catering"
          content="This is the catering page"
          url="/catering"
        />
        <NavTwo />
        <CateringMenu />
      </div>
    );
  }
}

export default Menu;