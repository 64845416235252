import React from 'react';
import { Helmet } from 'react-helmet'

const Seo = ({ title, content, url }) => {
    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={content} />
            {url && <link rel="canonical" href={url} />}
        </Helmet>
    );
};

export default Seo;