import React, { Component } from "react";
import NavTwo from "../Nav/NavTwo/NavTwo";
import Seo from "../Seo";

import LightGallery from "../LightGallery/LightGallery";

class Gallery extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <Seo
          title="Gallery"
          content="This is the gallery page at Saigon Alley & Kitchen"
          url="/gallery"
        />
        <NavTwo />
        <LightGallery />
      </div>
    );
  }
}

export default Gallery;
