import React, { Component } from "react";
import { withRouter } from "react-router";
import "./ContactSection.css";
import "./ContactStyles.css";
import axios from "axios";
import { Field, reduxForm } from "redux-form";

class ContactSection extends Component {
  async handleSubmit(e) {
    e.preventDefault();

    this.props.history.push("/thank-you");

    const contactName = document.getElementById("name").value;
    const contactEmail = document.getElementById("email").value;
    const contactMessage = document.getElementById("message").value;
    const contactSubject = document.getElementById("subject").value;

    try {
      const contactForm = await axios.post("/api/form", {
        contactName,
        contactEmail,
        contactMessage,
        contactSubject,
      });
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    return (
      <div className="contact-section">
        <div id="index-banner" className="header-contact">
          <div className="header-title">
            <h1 className="center">Contact</h1>
          </div>
        </div>

        <section className="container next contact__container">
          <p>
            If you would like to get in contact with Saigon Alley, you can call,
            email us directly or use the form below. Our team will support you
            as soon as possible.
          </p>
          <div class="row">
            <form
              id="contact__form"
              onSubmit={this.handleSubmit.bind(this)}
              method="POST"
            >
              <div className="form-group">
                {/* <label for="inputName">Name</label> */}
                <input
                  type="text"
                  required
                  className="form-control"
                  id="name"
                  aria-describedby="name"
                  placeholder="Full Name"
                />
              </div>
              <br />
              <div className="form-group">
                {/* <label for="inputEmail1">Email address</label> */}
                <input
                  type="email"
                  required
                  className="form-control"
                  id="email"
                  aria-describedby="emailHelp"
                  placeholder="Email Address"
                />
              </div>
              <br />
              <div className="form-group">
                {/* <label for="subjectField">Subject</label> */}
                <input
                  type="text"
                  required
                  className="form-control"
                  id="subject"
                  aria-describedby="subjectHelp"
                  placeholder="Subject"
                />
              </div>
              <br />
              <div className="form-group">
                {/* <label for="message">Message</label> */}
                <textarea
                  className="contact__message"
                  required
                  rows="5"
                  id="message"
                  placeholder="Message"
                ></textarea>
              </div>

              <button type="submit" className="submit">
                Submit
              </button>
            </form>
          </div>
        </section>
      </div>
    );
  }
}

export default withRouter(ContactSection);
