import React, { useState, useEffect } from "react";
import Modal from "react-responsive-modal";
import "./Reservation.css";
import ReactTooltip from "react-tooltip";
import { Link } from "react-router-dom";

const ReservationPopup = () => {
  const [spinner, setSpinner] = useState({
    loading: true,
  });

  const [open, setOpen] = useState(true);

  const hideSpinner = () => {
    setSpinner({
      loading: false,
    });
  };

  const onOpenModal = () => {
    setIsOpen(true);
    setOpen(true);
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      localStorage.setItem("open", JSON.stringify(isOpen));

      const EXPIRE_TIME = 15 * 60 * 1000;

      setTimeout(function () {
        localStorage.removeItem("open");
      }, EXPIRE_TIME);
    }
  }, []);

  const [isOpen, setIsOpen] = useState(() => {
    if (typeof window !== "undefined") {
      const show = localStorage.getItem("open");
      // return JSON.parse(show) ? true : false;

      if (show) {
        return false;
      } else {
        return true;
      }
    }
  });

  const onCloseModal = () => {
    if (typeof window !== "undefined") {
      localStorage.setItem("open", JSON.stringify(false));
    }
    setIsOpen(false);
    setOpen(false);
  };

  return (
    <div className="pop-up">
      <Modal open={open} onClose={onCloseModal} center>
        <span className="popup-title">MENUS</span>
        <br />
        <br />
        <div className="pop-up-buttons">
          <Link to="/midtown">Midtown</Link>
          <Link to="/natomas">Natomas</Link>
        </div>
      </Modal>
    </div>
  );
};

export default ReservationPopup;
