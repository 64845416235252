import React from 'react';
import $ from 'jquery';
import './ScrollToTop.css'

class ScrollToTop extends React.Component {
    constructor() {
        super();
    
        this.state = {
            intervalId: 0
        };
      }

    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll);
    }

    handleScroll() {
        var scroll = $(window).scrollTop();
        var scrollPosition = 50;

        if (scroll > scrollPosition) {
            $('.scroll-to-top').addClass('scrollToTop');
        } else {
            $('.scroll-to-top').removeClass('scrollToTop');
        }
    }

    scrollStep() {
        if (window.scrollY === 0) {
            clearInterval(this.state.intervalId);
        }
        window.scroll(0, window.scrollY - this.props.scrollStepInPx);
      }
      
      scroll() {
        let intervalId = setInterval(this.scrollStep.bind(this), this.props.delayInMs);
        //store the intervalId inside the state, 
        //so we can use it later to cancel the scrolling
        this.setState({ intervalId: intervalId });
      }

  render() {
    return (
      <div href='#' title='Back to top' 
                 id='scroll' className='scroll-to-top shift' 
                 onClick={ (event) => { 
                                event.preventDefault();
                                this.scroll(); 
                          }}>
          <i className='fas fa-chevron-up 2x'></i>
        </div>
    )
  }
}

export default ScrollToTop;

