import React, { Component } from "react";
import NavOne from '../Nav/NavOne/NavOne';
import ParallaxOne from '../Parallax/ParallaxOne/ParallaxOne';
// import Footer from '../Footer/Footer';
import Seo from "../Seo";

class Landing extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <Seo
          title="Saigon Alley Kitchen and Bar"
          content="Best vietnamese food in midtown sacramento."
        />
        <NavOne />
        <ParallaxOne />
        {/* <Footer /> */}
      </div>
    );
  }
}

export default Landing;