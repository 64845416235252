import React, { Component } from "react";
import NavTwo from '../Nav/NavTwo/NavTwo';
import footer from '../Footer/Footer';
import Success from '../Success/Success';
import Seo from "../Seo";

class About extends Component {

  render() {
    return (
      <div>
        <Seo
          title="Page is not found"
          content="This page is not available."
        />
        <NavTwo />
        <Success />
        <footer />
      </div>
    );
  }
}

export default About;