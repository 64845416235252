import React, { Component } from "react";
import "./NavTwo.css";
import $ from "jquery";
import logo from "../../../images/s-a.png";
import { Link } from "react-router-dom";
import Popup from "../../Popup/Popup";
import Reservation from "../../Popup/Reservation";
import PopupTwo from "../../Popup/PopupTwo";

export default class NavTwo extends Component {
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll() {
    var scroll = $(window).scrollTop();
    var objectSelect = $(".next");

    // .offset() retrieves current position of element relative to document
    var objectPosition = objectSelect.offset().top;

    if (scroll > objectPosition) {
      $(".nav-two").addClass("nav-background");
      $(".logo").addClass("logo-shrink");
      $("ul li a").addClass("nav-link-color-black");
    } else {
      $(".nav-two").removeClass("nav-background");
      $(".logo").removeClass("logo-shrink");
      $("ul li a").removeClass("nav-link-color-black");
      $("ul li a").addClass("nav-link-color-white");
      $(".nav").css("border-bottom", "0px");
    }
  }

  render() {
    return (
      <div className="nav-background">
        <nav className="nav-two">
          <div className="nav-wrapper">
            <ul id="nav-mobile" className="left hide-on-med-and-down">
              <li>
                <Link to="/">
                  <p style={{ display: "none" }}>home</p>
                  <img
                    width="auto"
                    height="auto"
                    className="logo"
                    src={logo}
                    alt="logo"
                  />
                </Link>
              </li>
            </ul>
            <div className="links shift">
              <ul id="nav-mobile" className="right hide-on-med-and-down">
                <li>{/* <div className="divide2"></div> */}</li>
                <li>
                  <Link to="/">HOME</Link>
                </li>
                {/* <li>
                        <Link to="/about">ABOUT</Link>
                    </li> */}
                <li>
                  <Link to="/midtown">MIDTOWN</Link>
                </li>
                <li>
                  <Link to="/natomas">NATOMAS</Link>
                </li>
                <li>
                  <Link to="/catering">CATERING ORDERS</Link>
                </li>
                <li>
                  <Link to="/gallery">GALLERY</Link>
                </li>
                {/* <li>
                  <Link to="/careers">CAREERS</Link>
                </li> */}
                <li>
                  <Link to="/contact">CONTACT</Link>
                </li>
                {/* <li>
                  <a href="https://os.resy.com/portal/booking/party" target="_blank" without="true" rel="noopener noreferrer">Reservation</a>
                </li> */}
                <li>
                  <Reservation />
                </li>
                <li>
                  <Popup />
                  {/* <a
                    href="https://www.toasttab.com/saigon-alley-kitchen-bar/v2/online-order#!/"
                    target="_blank"
                    without="true"
                    rel="noopener noreferrer"
                    className="order"
                  > */}
                  {/* ORDER ONLINE
                  </a> */}
                </li>
                <li>
                  <PopupTwo />
                </li>
                {/* <li>
                      <div className="divide2"></div>
                      
                       </li>
                       <li><Link to="https://www.facebook.com/saigonalleysac" target="_blank" without="true" rel="noopener noreferrer"><i className="fab fa-facebook-f"></i></Link></li>
                                    <Link to="" target="_blank" without="true" rel="noopener noreferrer"><i className="fab fa-twitter"></i></Link>
                                  <li>  <Link to="https://www.instagram.com/saigonalleysac" target="_blank" without="true" rel="noopener noreferrer"><i className="fab fa-instagram"></i></Link></li>
                                   <li> <Link to="https://www.yelp.com/biz/saigon-alley-kitchen-bar-sacramento" target="_blank" without="true" rel="noopener noreferrer"><i className="fab fa-yelp"></i></Link></li> */}
              </ul>
            </div>
          </div>
        </nav>

        {/* <nav className="navbar-appear">
                    <div class="nav-wrapper">
                        <Link to="#!" class="brand-logo">Saigon Alley</Link>
                        <Link to="#" data-target="mobile-demo" class="sidenav-trigger"><i class="material-icons">menu</i></Link>
                        <ul class="right hide-on-med-and-down">
                            <li>Home</li>
                            <li>About</li>
                            <li>Menu</li>
                            <li>Careers</li>
                            <li>Contact</li>
                            <li>Order Online</li>
                            <li>FB</li>
                            <li>IG</li>
                            <li>Youtube</li>
                        </ul>
                    </div>
                </nav>

                <ul class="sidenav" id="mobile-demo">
                    <li>Home</li>
                    <li>About</li>
                    <li>Menu</li>
                    <li>Careers</li>
                    <li>Contact</li>
                    <li>Order Online</li>
                </ul> */}
      </div>
    );
  }
}
