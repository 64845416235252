import React, { Component } from "react";
import "./ParallaxTwo.css";
// import bg from "../../../images/pho-brisket.jpg";
// import bg2 from "../../../images/pork-banhmi.jpg";
import M from "materialize-css";

// import $ from "jquery";

export default class Parallax2 extends Component {
    componentDidMount() {
        let elems = document.querySelectorAll('.parallax');
        M.Parallax.init(elems, {}); 

    }

    render() {
        return (
           <div className="parallax-two">
                <div id="index-banner" className="header">
                    <div className="section2">
                        <br/>
             
                        <h1 className="center">ABOUT SAIGON ALLEY</h1>
                    </div>
                </div>

                <div className="parallax-container section-1 next">
                    <div className="section">
                        <div className="container">
                            <div className="row center">
                            {/* <img src={saigon} alt /> */}
                            Saigon Alley Kitchen & Bar is located in the Handle District of Downtown Sacramento. We are a restaurant and bar unlike any other in the area with a beautiful and thought out interior design which consists of the bar area on the bottom level and an upstairs dining area. We pride ourselves in the quality of our food and providing our customers with an experience unlike anywhere else with the fusion of American and Vietnamese culture, flavors, and presentation. The food that we serve is inspired by the traditional flavors and recipes from Vietnam. Not only do we have delicious vietnamese inspired food, but we serve a wide range of cocktails and beers to choose from.
                            </div>
                        </div>
                    </div>
                    
                </div>

                 {/* <div className="section-2">
                    <div className="row">
                        <div className="col s12 m6 pub2">
                        <div className="pub2-container">
                            <h3>PUB</h3>
                            <p>A heart that beats strong and with a life of its own. Classic and creative cocktails, a menu of dishes to share and more.
                            </p>
                         
                            See more</div>
                            </div>
                        <div className="col s12 m6 pub2-image">
                            
                    </div>
                    </div>
                </div>

                <div className="section-2">
                    <div className="row">
                    <div className="col s12 m6 wine2-image">
                            </div>
                        <div className="col s12 m6 wine2">
                        <div className="wine2-container">
                        <h3>WINES</h3>
                            <p>More than 250 references from around the world, personalized recommendations and a pairing proposal for each tasting menu option.
                            </p>
                          
                            See book</div>
                            </div>
                    </div>
                </div> */}


                {/* <div className="parallax-container valign-wrapper bg-darken">
                    <div className="section-3 no-pad-bot">
                        <div className="container">
                            <div className="row center">
                                <div className="col s12 m6">
                                OUR LETTER
The autumn - winter 2019 menu is a tribute to Lima and all its mixtures; to their people, their stories and the love they knew and know how to build. This letter shows a tasting designed to give a sample of our gastronomic proposal.

Meet our letter
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="parallax"><img className="responsive-img" src={bg} alt="rice" /></div>
                </div> */}

                {/* <div className="parallax-container valign-wrapper bg-darken">
                    <div className="section-4 no-pad-bot">
                        <div className="container">
                            <div className="row">
                                
                            </div>
                        </div>
                    </div>
                    <div className="parallax"><img className="responsive-img" src={bg2} alt="dumpling" /></div>
                </div> */}

          
              
            </div>
           
        );
    }
}