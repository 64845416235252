import React, { Component } from "react";
import NavTwo from '../Nav/NavTwo/NavTwo';
import ParallaxTwo from '../Parallax/ParallaxTwo/ParallaxTwo';
import Seo from "../Seo";

class About extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  
  render() {
    return (
        <div>
          <NavTwo />
          <ParallaxTwo />
        </div>
    );
  }
}

export default About;