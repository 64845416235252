import React, { useState } from "react";
import Modal from "react-responsive-modal";
import "./Popup.css";
import ReactTooltip from "react-tooltip";

const Reservation = ({ noStyle }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [spinner, setSpinner] = useState({
    loading: true,
  });

  const hideSpinner = () => {
    setSpinner({
      loading: false,
    });
  };

  const onOpenModal = () => {
    setIsOpen(true);
  };

  const onCloseModal = () => {
    setIsOpen(false);
  };

  return (
    <>
      <a
        data-tip="Midtown Location"
        href="https://resy.com/cities/sf/saigon-alley-kitchen-and-bar"
        className={noStyle ? "" : "order"}
        target="_blank"
        rel="noopener noreferrer"
        // onClick={onOpenModal}
      >
        Reservations
      </a>
      {/* <ReactTooltip place="bottom" effect="solid" /> */}
      <Modal open={isOpen} onClose={onCloseModal} center>
        {spinner.loading ? (
          <div className="loader">
            <svg
              class="svgLoader"
              viewBox="0 0 100 100"
              width="10em"
              height="10em"
            >
              <path
                ng-attr-d="{{config.pathCmd}}"
                ng-attr-fill="{{config.color}}"
                stroke="none"
                d="M10 50A40 40 0 0 0 90 50A40 42 0 0 1 10 50"
                fill="rgba(255, 255, 255, 0.3)"
                transform="rotate(179.719 50 51)"
              >
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  calcMode="linear"
                  values="0 50 51;360 50 51"
                  keyTimes="0;3"
                  dur="3s"
                  begin="0s"
                  repeatCount="indefinite"
                ></animateTransform>
              </path>
            </svg>
          </div>
        ) : null}
        <iframe
          onLoad={hideSpinner}
          src="https://resy.com/cities/sf/saigon-alley-kitchen-and-bar"
          className="order-frame"
        ></iframe>
      </Modal>
    </>
  );
};

export default Reservation;
