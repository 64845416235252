import React, { Component } from "react";
import "lightgallery.js/dist/css/lightgallery.css";
import { LightgalleryProvider, LightgalleryItem } from "react-lightgallery";
import "./LightGalleryStyles.css";
import M from "materialize-css";
import ReactTooltip from "react-tooltip";

let mainDishes = [];
let appetizers = [];
let drinks = [];
let interior = [];

class LightGallery extends Component {
  importAll(r) {
    return r.keys().map(r);
  }
  componentWillMount() {
    mainDishes = this.importAll(
      require.context(
        "../../images/food/foodsAndDrinks/mainDishes",
        false,
        /\.(png|jpe?g|svg)$/
      )
    );
    appetizers = this.importAll(
      require.context(
        "../../images/food/foodsAndDrinks/appetizers",
        false,
        /\.(png|jpe?g|svg)$/
      )
    );
    drinks = this.importAll(
      require.context(
        "../../images/food/foodsAndDrinks/drinks",
        false,
        /\.(png|jpe?g|svg)$/
      )
    );
    interior = this.importAll(
      require.context("../../images/interior", false, /\.(png|jpe?g|svg)$/)
    );
  }

  componentDidMount() {
    M.Tabs.init(this.Tabs, {
      swipeable: true,
    });
  }

  render() {
    const PhotoItem = ({ image, group }) => (
      <div className="gallery__image__box">
        <LightgalleryItem group={group} src={image}>
          <img
            width="auto"
            height="auto"
            src={image}
            className="gallery__image"
            alt=""
          />
        </LightgalleryItem>
      </div>
    );

    return (
      <div className="parallax-two">
        <div id="index-banner" className="header">
          <div className="header-title">
            <h1 className="center">GALLERY</h1>
          </div>
        </div>
        <div className="container next">
          <div className="row">
            <ul
              ref={(Tabs) => {
                this.Tabs = Tabs;
              }}
              className="tabs"
            >
              <li className="tab col s3">
                <a data-tip="Main Dishes" className="active" href="#tab__one">
                  Main Dishes
                </a>
              </li>
              <li className="tab col s3">
                <a data-tip="Appetizers" href="#tab__two">
                  Appetizers
                </a>
              </li>
              <li className="tab col s3">
                <a data-tip="Drinks" href="#tab__three">
                  Drinks
                </a>
              </li>
              <li className="tab col s3">
                <a data-tip="Interior" href="#tab__four">
                  Interior
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="section-gallery" data-tip="Click to view more photos">
          <LightgalleryProvider
            onAfterSlide={() => {
              console.log("onAfterSlide");
            }}
          >
            <div id="tab__one" className="col s12">
              <div className="row gallery__row">
                {mainDishes.map((image, index) => (
                  <div key={index} className="col s12 m3">
                    <div className="box z-depth-1">
                      <PhotoItem image={image} group="group1" />
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div id="tab__two" className="col s12">
              <div className="row gallery__row">
                {appetizers.map((image, index) => (
                  <div key={index} className="col s12 m3">
                    <div className="box z-depth-1">
                      <PhotoItem image={image} group="group2" />
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div id="tab__three" className="col s12">
              <div className="row gallery__row">
                {drinks.map((image, index) => (
                  <div key={index} className="col s12 m4">
                    <div className="box z-depth-1">
                      <PhotoItem image={image} group="group3" />
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div id="tab__four" className="col s12">
              <div className="row gallery__row z-depth-1">
                {interior.map((image, index) => (
                  <div key={index} className="col s12 m3">
                    <div className="box">
                      <PhotoItem image={image} group="group4" />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </LightgalleryProvider>
        </div>
        {/* <ReactTooltip place="top" type="dark" effect="solid" /> */}
      </div>
    );
  }
}

export default LightGallery;
