
import React, { Component } from "react";
import "./Success.css";

class Success extends Component {

    render() {
        return (
            <div className="success">
               <div id="index-banner" className="header-success">
                    <div className="header-title">
                        <br />
                        <h1 className="center">Thank you!</h1>
                        <p>Your form has been submitted for review.</p>
                    </div>
                </div>

                <div className="next">
                    
              </div>
            </div>
           
        );
    }
}

export default Success;